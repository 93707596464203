.ant-tabs-nav .ant-tabs-tab-active {
  color: #263f97;
  opacity: 1;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #263f97;
  opacity: 1;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: bold;
  color: #263f97;
  opacity: 1;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  font-family: 'proxima-nova', sans-serif;
}
.ant-tabs-tab {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #263f97;
  opacity: 0.6;
}
.ant-tabs-ink-bar {
  background-color: #ff756c;
}
.ant-tabs-top > .ant-tabs-nav::before {
  /* border-color: #d4d6e6; */
  border: none;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 4px;
}

.mb-8 {
  margin-bottom: 8px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mr-12 {
  margin-right: 12px !important;
}
.mr-8 {
  margin-right: 8px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #263f97;
  border-color: #263f97;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #263f97;
}
.ant-picker-suffix {
  color: #263f97;
}
.ant-picker-separator {
  color: #263f97;
}
.ant-select-arrow {
  color: #263f97;
}
.ant-input-suffix svg {
  color: #263f97;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #263f97;
  opacity: 1;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #263f97;
  opacity: 1;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: bold;
  color: #263f97;
  opacity: 1;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  font-family: 'proxima-nova', sans-serif;
}
.ant-tabs-tab {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #263f97;
  opacity: 0.6;
}
.ant-tabs-ink-bar {
  background-color: #ff756c;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 4px;
}

/* .ant-input-affix-wrapper,
.ant-picker-range,
.ant-select-selector.ant-btn,
.ant-input {
  border-radius: 5px !important;
} */
